<template>
  <div class="page-wrapper">
    
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant">
        <img src="@/assets/img/tagline-fr.svg" v-if="$route.meta.lang === 'fr'" class="tagline" alt="Tagline Tremblant">
        <img src="@/assets/img/tagline-en.svg" v-else-if="$route.meta.lang === 'en'" class="tagline" alt="Tagline Tremblant">
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang">FR</router-link>
        </div>
        <a :href="$t('site_hiver_2023.al_entrepot_link')" target="_blank" class="cta red">{{ $t('site_hiver_2023.buy_tickets') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone4.svg" alt="Call Tremblant"></a>
      <div class="mobile-cta">
        <a :href="$t('site_hiver_2023.al_entrepot_link')" target="_blank" class="cta red">{{ $t('site_hiver_2023.buy_tickets') }}</a>
      </div>
    </header>

    <section class="terms-wrap">
      <div v-if="$route.meta.lang === 'fr'">

        <button @click.prevent="goback" class="cta prune">Retour</button><br><br>

        <h1 class="big-title">Modalités<br>Entrepôt de billets Tremblant</h1>

        <h2 class="small-title">Validité</h2>
        <ul>
          <li class="regular-text">Tous les billets achetés sur l’Entrepôt de billets Tremblant sont prépayés et valables uniquement pour la journée choisie.</li>
          <li class="regular-text">Les billets 2 jours, 3 jours, 4 jours et 5 jours doivent être utilisés de manière consécutive.</li>
          <li class="regular-text">Les billets d’après-midi sont valables à partir de 12h. Les prix ne varient pas.</li>
          <li class="regular-text">Les prix peuvent varier sans préavis.</li>
        </ul>
        
        <h2 class="small-title">Achat</h2>
        <ul>
          <li class="regular-text">Les billets peuvent être achetés sur l’Entrepôt de billets Tremblant en tout temps.</li>
          <li class="regular-text">Pour profiter de cette offre, les billets doivent être achetés au moins 24h à l’avance pour les billets valables du lundi au vendredi et au moins 48h à l’avance pour les billets valables le samedi et dimanche.</li>
          <li class="regular-text">Les billets d’après-midi peuvent être achetés par téléphone au 1-888-738-1777.</li>
          <li class="regular-text">Les billets bambin (0-2 ans) sont en vente uniquement sur place ou par téléphone.</li>
        </ul>

        <h2 class="small-title">Livraison</h2>
        <ul>
          <li class="regular-text">Vous devez récupérer votre billet à l’un des comptoirs multi-services (carte d'identité avec photo obligatoire) ou aux bornes d’impression la veille ou le jour même de votre arrivée.</li>
          <li class="regular-text">Si achat à l’avance, vous recevrez un code QR 24h avant la première date d’utilisation prévue. Si achat le jour même, vous recevrez un courriel contenant le code QR quelques minutes suivant votre achat. Présentez-vous directement à une borne d’impression avec ce code.</li>
          <li class="regular-text">Si vous avez réservé votre produit avec votre hébergement sur le site 72h avant votre arrivée, le billet vous sera livré directement à votre hôtel.</li>
        </ul>

        <h2 class="small-title">Remboursement</h2>
        <p class="regular-text">Remboursable jusqu’à 3 jours (72 heures) avant la première journée d’utilisation prévue. Non remboursable après ce délai.</p>

        <h2 class="small-title">Restrictions</h2>
        <p class="regular-text">Non transférable.</p>

      </div>

      <div v-else-if="$route.meta.lang === 'en'">

        <button @click.prevent="goback" class="cta prune">Back</button><br><br>

        <h1 class="big-title">Tremblant Ticket Outlet<br>Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <ul>
          <li class="regular-text">All tickets purchased through the Tremblant Ticket Outlet are prepaid and date specific.</li>
          <li class="regular-text">2-day, 3-day, 4-day and 5-day tickets must be used consecutively.</li>
          <li class="regular-text">Prices may vary.</li>
          <li class="regular-text">Afternoon (half-day) tickets are valid after 12PM. Prices do not vary.</li>
        </ul>

        <h2 class="small-title">Purchase</h2>
        <ul>
          <li class="regular-text">Tickets can be purchased on the Tremblant Ticket Outlet at all times.</li>
          <li class="regular-text">To take advantage of this offer, tickets valid Monday to Friday must be purchased at least 24h in advance and tickets valid Saturday and Sunday must be purchased at least 48h in advance.</li>
          <li class="regular-text">Afternoon ticket can be purchased by phone at 1-888-738-1777.</li>
          <li class="regular-text">Toddler tickets (0-2 y.o.) must be purchased at the ticket offices or by phone.</li>
        </ul>

        <h2 class="small-title">Delivery</h2>
        <ul>
          <li class="regular-text">You can pick-up your ticket at one of our multi-services counters (photo ID required) or ticket printing kiosks the before or day of your arrival.</li>
          <li class="regular-text">If purchased in advance, you will receive an email containing a QR Code 24h prior to your arrival. If purchased the same day, you will receive an email containing a QR Code a few minutes after your purchase. Please present this code directly at the ticket printing kiosks.</li>
          <li class="regular-text">If you purchased your ticket with onsite lodging 72h before your arrival, it will be delivered to your hotel.</li>
        </ul>

        <h2 class="small-title">Refund</h2>
        <p class="regular-text">Can be refunded up to 72 hours prior to first planned day of use. Not refundable past this period.</p>

        <h2 class="small-title">Restrictions</h2>
        <p class="regular-text">Non-transferable.</p>

     </div>
      
    </section>

    <footer-bar></footer-bar>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform">
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              name="arrivaldate"
              v-model="time1"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'arrivaldate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              name="departuredate"
              v-model="time2"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'departuredate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input
              name="adultcount"
              type="number"
              value="2"
              min="1"
              required
            >
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input
              name="childCount"
              type="number"
              value="0"
              required
            >
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')">
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: "Éclaté de haut en bas"
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Tremblant - Offres` },
        {
          name: 'description',
          content: `Profitez des meilleures offres et prix de l'année.`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `Tremblant - Offres` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Profitez des meilleures offres et prix de l'année.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Tremblant - Offres` },
        { property: 'og:site_name', content: `Tremblant - Offres` },
        {
          property: 'og:description',
          content: `Profitez des meilleures offres et prix de l'année.`
        },
        // Og Image
        {
          p: 'og:image',
          c:
            ''
        },
        {
          name: 'twitter:image',
          content: ''
        }
      ]
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null
    }
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    goback() {
        return this.$router.go(-1)
    },
    table1() {
        this.toggle = true
    },
    table2() {
        this.toggle = false
    },
    showlodgingform() {
        if(this.formlodging) {
          this.formlodging = false
        } else {
          this.formlodging = true
        }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
        if(this.sidepanel) {
          this.sidepanel = false
        } else {
          this.sidepanel = true
        }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${ -150 + st /2}px`
    } 
  },
  mounted () {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
      window.addEventListener('scroll', () => {
        this.snowscroll()
      })
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
